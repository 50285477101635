/** @jsx jsx */
import { jsx, Container, Flex, Box, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PracticeAreas from "../components/sections/practiceAreas"

const Practice = ({ data: { site, file, allService } }) => {
  return (
    <Layout>
      <section
        sx={{
          py: 5,
          backgroundImage: `url("${file.publicURL}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}>
        <Container>
          <Flex>
            <Box
              sx={{
                width: ['full'],
                px: 5,
                mx: 'auto'
              }}>
              <Styled.h1>
                Areas of Practice
              </Styled.h1>
              <Styled.h5
                sx={{
                  fontWeight: '300'
                }}>
                  { site.siteMetadata.title }
              </Styled.h5>
            </Box>
          </Flex>
        </Container>
      </section>
      <section
        sx={{
          py: 4 
        }}>
          <PracticeAreas services={allService.nodes} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(name: { eq: "office-white" }){
      publicURL
    }
    allService{
      nodes{
        id
        icon{
          publicURL
        }
        slug
        name
        description
      }
    }
  }
`

export default Practice 