/** @jsx jsx */
import { jsx, Container, Flex, Box, Styled } from "theme-ui"
import Service from "../service"

const PracticeAreas = ({ services }) => {

  return (
    <Container
      sx={{
        py: 6
      }}>
      <Flex
        sx={{
          justifyContent: 'center',
          mb: 6
        }}>
        <Box>
          <Styled.h1
            sx={{
              m: 0
            }}>
            Areas of Law Practice
          </Styled.h1>
          <Styled.h6
            sx={{
              fontWeight: '300',
              textAlign: 'center'
            }}>
            Our attorneys are licensed and specialize in these areas of legal practice
            </Styled.h6>
        </Box>
      </Flex>
      <Flex
        sx={{
          mt: 5,
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}>
        {services.map(service => (
          <Box
            key={service.id}
            sx={{
              mx: 3,
              width: ['1/2', '1/4'],
              mb: 6
            }}>
            <Service service={service} /> 
          </Box>
        ))}
      </Flex>
    </Container>
  )
}

export default PracticeAreas