/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"

const Service = ({ service }) => {

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bg: 'gray.2',
        borderRadius: '0 0 50px 0',
        boxShadow: 'lg',
        p: 4,
        pt: 5,
        textAlign: 'center',
        position: 'relative',
        height: 'full'
      }}>
      <div
        sx={{
          p: 4,
          borderRadius: 'full',
          bg: 'primary',
          position: 'absolute',
          top: '-50px',
          left: '50%',
          ml: '-55px'
        }}>
        <img
          src={service.icon.publicURL}
          alt={service.name}
          sx={{
            width: '65px',
            height: '55px'
          }} />
      </div>
      <p
      sx={{
        mt: 4,
        mb: 'auto',
      }}>{service.description}</p>
      <Styled.h5
        sx={{
          fontSize: 2,
          my: 2,
          color: 'secondary'
        }}>{service.name}
      </Styled.h5>
      <Link
        to={`/${service.slug}`}
        sx={{
          bg: 'primary',
          borderStyle: 'solid',
          borderColor: 'primary',
          borderWidth: 1,
          color: 'white',
          px: 4,
          py: 2,
          m: theme => `${theme.space[2]} auto 0`,
          display: 'inline-block',
          width: 'fit-content',
          fontFamily: 'heading',
          textTransform: 'uppercase',
          fontSize: 0,
          borderRadius: '0 0 15px 0'
        }}>
        Learn More
      </Link>
    </div>
  )
}

export default Service